$base-r: 22;
$base-g: 18;
$base-b: 32;

@function mixWhite( $alpha ) { @return rgba(255, 255, 255, $alpha); }
@function mixBase( $alpha ) { @return rgba($base-r, $base-g, $base-b, $alpha); }

$dark-scheme--background: mixBase(1);
$dark-scheme--foreground: mixWhite(1);
$dark-scheme--soft-foreground: mixWhite(0.65);
$dark-scheme--post-background: mixWhite(0.08);
$dark-scheme--form-select-indicator-color: mixWhite(1);
$dark-scheme--basic-button-foreground: mixBase(0.9);
$dark-scheme--basic-button-background: darken(mixWhite(1), 45%);
$dark-scheme--dimmed-opaque-foreground: mixWhite(0.95);
$dark-scheme--dimmed-opaque-background: darken(mixBase(0.90), 7%);

// $light-scheme--background: mixBase(0.03); // don't use, cos page scrollbar becomes invisible when the opacity is so low.
$light-scheme--background: rgba(247, 247, 248);
$light-scheme--foreground: mixBase(0.87);
$light-scheme--soft-foreground: mixBase(0.35);
$light-scheme--post-background: mixWhite(1);
$light-scheme--basic-button-foreground: mixWhite(0.95);
$light-scheme--basic-button-background: mixBase(0.30);
$light-scheme--dimmed-opaque-foreground: mixBase(0.87);
$light-scheme--dimmed-opaque-background: lighten(mixBase(0.95), 85%);
$light-scheme--box-shadow-color: mixBase(0.05);

$post--border-radius: 8px;

$icon-url--up-button   : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M2 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2zm6.5-4.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 1 0z'/%3E%3C/svg%3E");
$icon-url--down-button : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5a.5.5 0 0 1 1 0z'/%3E%3C/svg%3E");

body {
  color-scheme: light dark;
  background-color: $light-scheme--background;
  color: $light-scheme--foreground;
  padding-bottom: 120px;
}
body.dark {
  color-scheme: dark light;
  background-color: $dark-scheme--background;
  color: $dark-scheme--foreground;
}
body.dark a,
body.dark a:hover {
  color: #429fff;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Zilla Slab', sans-serif;
  font-weight: 600;
}

h1 .tagline {
  font-size: 0.4em;
  text-transform: uppercase;
  opacity: 0.8;
  .bi {
    width: 0.9em;
    height: 0.9em;
    margin-top: -0.15em;
  }
}
h1 .subtag {
  font-size: 1.2rem;
}

.tagline a,
body.dark .tagline a {
  color: inherit;
}
aside h2 {
  font-size: 1.8rem;
}
.chord-chart h2 {
  font-size: 2.4rem;
}

h4.inline,
h5.inline {
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
}
p + h3,
p + h4,
p + h5 {
  margin-top: 1em;
}
figure + h3,
figure + h4,
figure + h5 {
  margin-top: 1em;
}

.row {
  margin-left: unset;
  margin-right: unset;
}

.site-wrapper.container {
  max-width: 1200px;
}
.site-wrapper.container-md {
  max-width: 992px;
}

.site-header {
  margin-top: 4vw;
  position: relative;
  margin-bottom: 1.5em;
}
@include media-breakpoint-down(sm) {
  .site-header {
    margin-top: 24px;
  }
}

.site-footer {
  text-align: center;
  font-size: 0.9em;
  color: $light-scheme--soft-foreground;
}
body.dark .site-footer {
  color: $dark-scheme--soft-foreground;
}

h1 {
  margin-bottom: 0;
}


body.dark img.can-invert {
  filter: invert(0.8);
  opacity: 0.9;
}
@include media-breakpoint-down(sm) {
  figure img {
    width: 100%;
    height: auto;
  }
}

.site-header .tools {
  display: inline-block;
  width: auto;
  margin-top: -6px;
}
@include media-breakpoint-down(md) {
  .site-header .tools {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.site-intro {
  margin-bottom: 2.5em;
  font-size: 18px;
}
.between-post {
  margin-bottom: 2.5em;
}
.form-check-input { 
  background-color: rgba(210, 210, 230, 0.55);
}

.form-select {
  display: inline-block;
  width: auto;
}
body.dark .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
}

.btn {
  border: none;
  padding: 0;
  background: $light-scheme--basic-button-background;
  color: $light-scheme--basic-button-foreground;
}
body.dark .btn {
  background: $dark-scheme--basic-button-background;
  color: $dark-scheme--basic-button-foreground;
}

.underline-em em {
  text-decoration: underline;
}
ul.spaced > li,
ol.spaced > li {
  margin-bottom: 1em;
}

.post {
  margin-bottom: 3em;
  padding-top: 16px;
  padding-bottom: 24px;
  border-radius: $post--border-radius;
  box-shadow: 0 12px 12px $light-scheme--box-shadow-color;
  background-color: $light-scheme--post-background;
}
body.dark .post {
  background-color: $dark-scheme--post-background;
  box-shadow: none;
}

.post .intro {
  overflow-y: hidden;
  position: relative;
  transition: max-height 0.8s;
  max-height: 1200px;
  padding-bottom: 3em;
  margin-bottom: 2em;
  padding-top: 3em;
  margin-top: -3em;
}
.post .intro.less {
  max-height: 320px;
  padding-bottom: 0;
}
.post .intro.no-more {
  padding-bottom: 0;
}
@include media-breakpoint-down(md) {
  .post .intro {
    max-height: 2000px;
    padding-top: 0;
    margin-top: 0;
  }
  .post .intro.less {
    max-height: 360px;
  }   
}

pre, .pre {
  font-family: 'Noto Sans Mono', monospace;
  white-space: pre;
}
.tight-line-break,
.tight-line-break > * {
  margin-bottom: 0;
}
.indent {
  margin-left: 4em;
}
@include media-breakpoint-down(sm) {
  .indent {
    margin-left: 1em;
  }
}


.video {
  float: right;
  aspect-ratio: 16 / 9;
  width: 45%;
  margin: -3em 0 0.5em 0.5em;
}
@include media-breakpoint-down(md) {
  .video {
    width: 50%;
    margin-top: 0;
    background-color: red;
  }
}
@include media-breakpoint-down(sm) {
  .video {
    float: none;
    width: 100%;
    margin-left: 0;
    margin-top: 0.5em;
  }
}

.post .reveal-handle {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  height: 6em;
}

.post .reveal-handle .mask {
  display: none;
  height: 3em;
  background: linear-gradient(to bottom, mixWhite(0) 0%, $light-scheme--post-background 100%);
}
body.dark .post .reveal-handle .mask {
  background: linear-gradient(to bottom, mixBase(0) 0%, mixBase(65) 100%);
}
.post .intro.less .reveal-handle .mask {
  display: block;
}

.post .reveal-handle button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3em;
  border: 0;
  background-color: $light-scheme--dimmed-opaque-background;
}

body.dark .post .reveal-handle button {
  background-color: $dark-scheme--dimmed-opaque-background;
}

.post .reveal-handle label {
  cursor: pointer;
  text-transform: capitalize;
  color: $light-scheme--dimmed-opaque-foreground;
}

body.dark .post .reveal-handle label {
  color: $dark-scheme--dimmed-opaque-foreground;
}

.post .reveal-handle label.more {
  display: none;
}

.post .reveal-handle label.less {
  display: inline-block;
}

.post .intro.no-more .reveal-handle {
  display: none;
}
.post .intro.less .reveal-handle label.more {
  display: inline-block;
}

.post .intro.less .reveal-handle label.less {
  display: none;
}

.chord-chart {
  // margin-bottom: -3em;
  font-family: 'Noto Sans Mono', monospace;
}
.chord-chart h3 {
  margin: 0;
}
.chord-chart .subhead {
  margin: 0.5em 0 1em;
  opacity: 0.55;
}

label.stanza {
  display: inline-block;
  margin: 0.2em 0 -0.2em;
  padding: 6px;
  border: 1px solid $light-scheme--foreground;
  border-left-width: 6px;
  line-height: 1;
  text-transform: uppercase;
}
body.dark label.stanza {
  border-color: $dark-scheme--foreground;
}
label.stanza h4 {
  margin: 0;
  font-size: 1em;
  font-family: "Noto Sans Mono", monospace;
}

.chord-chart .head {
  margin-bottom: -0.5em;
}
.chord-chart .head > * {
  margin-bottom: 0.5em;
}
.chord-chart .body.indent {
  margin-left: 2em;
}
@include media-breakpoint-down(sm) {
  .chord-chart .body.indent {
    margin-left: 1em;
  }
}

.chord-chart .body:last-child {
  margin-bottom: 4em;
}
.chord-chart .head + .body {
  margin-top: 2em;
}
.chord-chart .body + .body {
  margin-top: 3em;
}
.chord-chart .line {
  position: relative;
  line-height: 3.2em;
  font-size: 1.5em;
  margin-bottom: 1.2em;
}
.chord-chart .subtitle + .line {
  margin-top: -0.5em;
}
.chord-chart .line.chordless .chords {
  display: none;
}
.chord-chart .line.chordless .words {
  position: relative;
  // top: 0;
  line-height: 1.5em;

}

.chord-chart .line.wordless {
  margin-bottom: 0;
}
.chord-chart .line.wordless .words {
  display: none;
}

.chord-chart .chords>*
,.chord-chart .words>*{
  display: inline-block;
  white-space: pre;
}
.chord-chart .words {
  position: absolute;
  top: 1.3em;
  opacity: 0.55;
}
.chord-chart .line-break {
  display: block;
}

.chord-chart .room-0 { width: 0ch; display: none; }
.chord-chart .room-1 { width: 1ch; }
.chord-chart .room-2 { width: 2ch; }
.chord-chart .room-3 { width: 3ch; }
.chord-chart .room-4 { width: 4ch; }
.chord-chart .room-5 { width: 5ch; }
.chord-chart .room-6 { width: 6ch; }
.chord-chart .room-7 { width: 7ch; }
.chord-chart .room-8 { width: 8ch; }
.chord-chart .room-9 { width: 9ch; }
.chord-chart .room-10 { width: 10ch; }
.chord-chart .room-11 { width: 11ch; }
.chord-chart .room-12 { width: 12ch; }
.chord-chart .room-13 { width: 13ch; }
.chord-chart .room-14 { width: 14ch; }
.chord-chart .room-15 { width: 15ch; }
.chord-chart .room-16 { width: 16ch; }
.chord-chart .room-17 { width: 17ch; }
.chord-chart .room-18 { width: 18ch; }
.chord-chart .room-19 { width: 19ch; }
.chord-chart .room-20 { width: 20ch; }
.chord-chart .room-21 { width: 21ch; }
.chord-chart .room-22 { width: 22ch; }
.chord-chart .room-23 { width: 23ch; }
.chord-chart .room-24 { width: 24ch; }
.chord-chart .room-25 { width: 25ch; }
.chord-chart .room-26 { width: 26ch; }
.chord-chart .room-27 { width: 27ch; }
.chord-chart .room-28 { width: 28ch; }
.chord-chart .room-29 { width: 29ch; }
.chord-chart .room-30 { width: 30ch; }

.transposer-wrapper {
  display: flex;
  align-items: center;
}
.transposer-wrapper label {
  margin-right: 2px;
}
.key-select .step {
  height: 2.5em;
  aspect-ratio: 1;
  margin-left: 5px;
  margin-top: -3px;
  background-color: transparent;
}
body.dark .key-select .step {
  background-color: transparent;
}
.key-select .step::before {
  display: block;
  width: 100%;
  height: 100%;
  content: '';
  background-color: $light-scheme--basic-button-background;
  mask-size: cover;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  -webkit-mask-repeat: no-repeat;
}
body.dark .key-select .step::before {
  background-color: $dark-scheme--basic-button-background;
}
.key-select .up::before {
  mask: $icon-url--up-button;
  -webkit-mask: $icon-url--up-button;
}
.key-select .down::before {
  mask: $icon-url--down-button;
  -webkit-mask: $icon-url--down-button;
}

.font-size-changer {
  display: flex;
  margin-left: 1em;
}
.font-size-changer button {
  vertical-align: bottom;
  height: 2.5em;
  aspect-ratio: 1;
  border: none;
  border-radius: 5px;
}
.font-size-changer button.down {
  padding-left: 3px;
}
.font-size-changer button.up {
  padding-left: 2px;
  height: 1.66em;
  line-height: 1.5em;
  font-size: 1.5em;
}
.font-size-changer button.end {
  border: 1px solid $light-scheme--soft-foreground;
  background: none;
  color: $light-scheme--soft-foreground;
}
body.dark .font-size-changer button.end {
  border: 1px solid $dark-scheme--soft-foreground;
  background: none;
  color: $dark-scheme--soft-foreground;
}
.font-size-changer button:first-of-type {
  margin-right: 5px;
}

figure {
  margin-bottom: 1rem;
}
figure img {
  max-width: 100%;
  height: auto;
}
figure.fluid img {
  width: 100%;
}
figcaption {
  margin: 0.5em 0 1.5em;
  font-size: 0.85em;
  font-style: italic;
}
figure.chord-chart {
  margin-top: 2em;
}
figure.chord-chart .head+.body {
  margin-top: 0.5em;
}

table td,
table th {
  margin: 0;
  padding: 8px;
  border: none;
  font-weight: normal;
  text-align: left;
  line-height: 1.2;
}
table th {
  vertical-align: bottom;
}
table.line td,
table.line th {
  border: 1px solid #333; /* $light-scheme--foreground */
}
body.dark table.line td,
body.dark table.line th {
  border: 1px solid #fff; /* $dark-scheme--foreground */
}

@media print {
  body,
  body.dark,
  body *,
  body.dark * {
    color: black;
    opacity: 1;
  }
  .video,
  .tool {
    display: none;
  }
}