// $body-bg: #161220;
// $body-color: #fff;

$font-family-base:            'Inter', sans-serif;
$font-size-base:              1rem;
$line-height-base:            1.6;

$h1-font-size:                $font-size-base * 3.75 !default;
$h2-font-size:                $font-size-base * 2.75 !default;
$h3-font-size:                $font-size-base * 2.4 !default;
$h4-font-size:                $font-size-base * 2.2 !default;
$h5-font-size:                $font-size-base * 1.8 !default;
$h6-font-size:                $font-size-base * 1.5 !default;

$grid-gutter-width:           2.5rem !default;

$form-select-color:           inherit;
$form-select-bg:              transparent;
$form-select-indicator-padding:  2rem;